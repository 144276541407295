var _throttle = require('lodash/throttle');

function swiperMode(rowGridId) {
	const $rowGridContainer = $(`#${rowGridId}`);
	let init = false;

	const $rowSwiper = $rowGridContainer.find('.rowgridswiper .rowgrid-tile-container');

	$rowSwiper.children('.rowgrid-tile-item').removeClass('d-none');

	// assign classes on landing: if 4 or less, make a grid. If more, make a swiper
	if ($rowSwiper.children('div').length <= 4) {
		$rowSwiper.addClass('row ml-n2 mr-n2');
		$rowSwiper.children('div').addClass('col-md px-1');
		$rowGridContainer.find('.swiper-button').addClass('d-none');
	} else {
		$rowSwiper.addClass('swiper-wrapper');
		$rowSwiper.children('div').addClass('swiper-slide');
		$rowSwiper.children('.swiper-button').removeClass('d-none');
	}

	// if less than 3 graphicinfotiles, hide the whole thing.
	if ($rowSwiper.children('div').length <= 2) {
		$rowGridContainer.addClass('d-none');
	}

	// define the media queries
	const mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
	const desktop = window.matchMedia('(min-width: 768px)');

	// enable (for mobile)
	if (mobile.matches) {
		if (!init) {
			init = true;
			$rowSwiper.removeClass('row ml-n2 mr-n2').addClass('swiper-wrapper');
			$rowSwiper.children('div').removeClass('col-md px-1').addClass('swiper-slide');
			$rowGridContainer.find('.swiper-button').removeClass('d-none');

			// if there's 4 or more items in the grid, show these swiper parameters
			if ($rowSwiper.children('div').length >= 4) {
				new Swiper(`#${rowGridId} .swiper-grid .rowgridswiper`, {
					observer: true,
					observeParents: true,

					pagination: {
						clickable: true
					},
					spaceBetween: 5,
					loop: false,
					navigation: {
						nextEl: `#${rowGridId} .swiper-grid .swiper-button-next`,
						prevEl: `#${rowGridId} .swiper-grid .swiper-button-prev`,
						disabledClass: 'd-none'
					},
					breakpoints: {
						1200: {
							slidesPerView: 4.5,
							slidesPerGroup: 4,
							spaceBetween: 5
						},
						768: {
							slidesPerView: 3.1,
							slidesPerGroup: 3,
							spaceBetween: 5
						},
						360: {
							slidesPerView: 2.1,
							slidesPerGroup: 1,
							spaceBetween: 5
						}
					}

				});
			}
			// if there's 3 items in the grid, show these swiper parameters
			if ($rowSwiper.children('div').length === 3) {
				new Swiper(`#${rowGridId} .swiper-grid .rowgridswiper`, {
					observer: true,
					observeParents: true,

					pagination: {
						clickable: true
					},
					spaceBetween: 5,
					loop: false,
					navigation: {
						nextEl: `#${rowGridId} .swiper-grid .swiper-button-next`,
						prevEl: `#${rowGridId} .swiper-grid .swiper-button-prev`,
						disabledClass: 'd-none'
					},
					breakpoints: {
						768: {
							slidesPerView: 2.1,
							slidesPerGroup: 3,
							spaceBetween: 5
						},
						360: {
							slidesPerView: 1.2,
							slidesPerGroup: 1,
							spaceBetween: 5
						}
					}

				});
			}
		}
	} else if (desktop.matches) {
		// Disable (for desktop) if more than 4 slides
		if ($rowSwiper.children('div').length <= 4) {
			init = false;
			$rowSwiper.addClass('row ml-n2 mr-n2').removeClass('swiper-wrapper');
			$rowSwiper.children('div').addClass('col-md px-1').removeClass('swiper-slide');
		} else {
			$rowSwiper.removeClass('row ml-n2 mr-n2').addClass('swiper-wrapper');
			$rowSwiper.children('div').removeClass('col-md px-1').addClass('swiper-slide');
			$rowGridContainer.find('.swiper-button').removeClass('d-none');
			new Swiper(`#${rowGridId} .swiper-grid .rowgridswiper`, {
				observer: true,
				observeParents: true,

				pagination: {
					clickable: true
				},
				spaceBetween: 5,
				loop: false,
				navigation: {
					nextEl: `#${rowGridId} .swiper-grid .swiper-button-next`,
					prevEl: `#${rowGridId} .swiper-grid .swiper-button-prev`,
					disabledClass: 'd-none'
				},
				breakpoints: {
					1200: {
						slidesPerView: 4.5,
						slidesPerGroup: 4,
						spaceBetween: 5
					},
					768: {
						slidesPerView: 3.1,
						slidesPerGroup: 3,
						spaceBetween: 5
					},
					360: {
						slidesPerView: 2.1,
						slidesPerGroup: 1,
						spaceBetween: 5
					}
				}

			});
		}
	}
}

function swiperSetup() {
	Array.from(document.querySelectorAll(".pd-l-rowgrid"))
		.map((item) => item.id)
		.forEach((id) => {
			swiperMode(id);
		});
}

// removing the throttle for mobile devices as the browser fires resize every time user scrolls (due to hiding and showing the nav bar)
if ($(window).width() > 768) {
	$(window).on('resize', _throttle(swiperSetup, 250));
	swiperSetup();
} else {
	swiperSetup();
}

// Lazy load images while entering viewport
window.lazyLoadImgSrcInstance && window.lazyLoadImgSrcInstance.update();
